import React from "react";
import _ from "lodash";
import { Trans } from "@lingui/macro";
import {
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import { LocalizedLink, useLocalization } from "gatsby-theme-i18n";
import Reaptcha from "reaptcha";
import Container from "../components/Container";
import NewLayout from "../components/NewLayout";
import contact_image from "../images/contact-company.png";
import InterestedItems from "../components/InterestedItems";
import { optionsOffices } from "../utils/common";
import { headers } from "../utils/navbar";
import Checkbox from "../components/Checkbox";
import DownloadPdfButton from "../components/DownloadPdfButton";
import Mail from "../services/MailService";
import LoadingButton from "../components/LoadingButton";

const FILES = {
  en: "https://app.allfunds.com/docs/cms/202406_Client_Complaints_Procedure_EN_vf_334202b99d.pdf",
  es: "https://app.allfunds.com/docs/cms/202406_Reglamento_de_Atencion_al_Cliente_ES_6f563b392d.pdf",
};

const optionsRadio = () => [
  {
    value: "Technical assistance",
    label: <Trans>Technical assistance</Trans>,
    link: {
      title: (
        <Trans>
          Learn more about Allfunds’ Complaint Information and Client Complaint
          handling Procedure.
        </Trans>
      ),
      path: (locale) => FILES[locale] || FILES.en, //TODO: pass lang and get the correct
    },
  },
  {
    value: "Operational assistance",
    label: <Trans>Operational assistance</Trans>,
  },
  {
    value: "Client Complaints",
    label: <Trans>Claims and Complaints</Trans>,
  },
  { value: "Other", label: <Trans>Other</Trans> },
];

const optionsOperationalAssistance = () => [
  {
    value: "fund-house",
    label: <Trans>I’m a Fund House</Trans>,
  },
  { value: "distributor", label: <Trans>I’m a Distributor</Trans> },
];

const inputs = () => [
  { value: "name", label: <Trans>Full name</Trans> },
  { value: "email", label: <Trans>Email</Trans> },
  { value: "company", label: <Trans>Company</Trans> },
  { value: "phone", label: <Trans>Phone number</Trans> },
  { value: "message", label: <Trans>Message</Trans> },
];

const defaultComplaintEmails = [
  "atencionalcliente@allfunds.com",
  "complaintshandlingservice@allfunds.com",
];

function CustomerCarePage() {
  const [data, setData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const { locale } = useLocalization();
  const messageInput = _.last(inputs());
  const interestedItems = [
    { ..._.get(headers(), "investors.elements.investors_about") },
    { ..._.get(headers(), "investors.elements.governance.elements.allfunds_bank") },
    { ..._.get(headers(), "investors.elements.share_information") },
  ];

  const sendContact = () => {
    setLoading(true);
    const currentEmails =
      data?.complaint_emails?.data?.map(({ attributes: { email } }) => email) ||
      [];
    const complaint_emails = [...defaultComplaintEmails, ...currentEmails];

    const requestData = {
      ...data,
      complaint_emails,
      request: "Website",
      title: "Client Care",
      subtitle: "Website - Client Care",
      subject: "Website - Client Care",
    };

    Mail.sendContactRequest(requestData).then(({ status }) => {
      if (status === 200) {
        setLoading(false);
        setData({});
      }
    });
  };

  return (
    <NewLayout
      byDefault
      apps={[{ label: <Trans>Client Care</Trans>, path: "/customer-care" }]}
    >
      <Container pb={8}>
        <Typography mt={3} mb={3} variant="h2">
          <Trans>Client Care</Trans>
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                "*": {
                  fontSize: 16,
                },
              }}
            >
              <Typography>
                <Trans>
                  Please fill out the form below according to the following
                  options:
                </Trans>
              </Typography>
              <ul>
                <li>
                  <Trans>
                    Please select Technical Assistance for any technical or
                    accessibility issues
                  </Trans>
                </li>
                <li>
                  <Trans>
                    For any queries specifically related to operations, please
                    select Operational Assistance
                  </Trans>
                </li>
                <li>
                  <Trans>
                    For formal complaints and claims, please select Claims &
                    Complaints
                  </Trans>
                </li>
                <li>
                  <Trans>
                    For other questions or feedback, please select Other
                  </Trans>
                </li>
              </ul>
            </Box>

            <Stack spacing={2} mt={2}>
              {inputs()
                .slice(0, inputs().length - 1)
                .map(({ value, label }) => (
                  <TextField
                    value={data[value] || ""}
                    label={label}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => setData({ ...data, [value]: e.target.value })}
                    required
                    disabled={loading}
                  />
                ))}
              <Grid container spacing={1} alignItems="center">
                {optionsRadio().map(({ label, value }) => (
                  <Grid item xs={12} sm={6} lg={3}>
                    <Checkbox
                      checked={value === data.client}
                      onChange={() => setData({ ...data, client: value })}
                    >
                      {label}
                    </Checkbox>
                  </Grid>
                ))}
              </Grid>
              {optionsRadio()
                .filter((item) => item.link)
                .map(({ link }) => (
                  <DownloadPdfButton
                    path={link.path(locale)}
                    title={link.title}
                  />
                ))}
              <TextField
                value={data[messageInput.value] || ""}
                label={messageInput.label}
                variant="outlined"
                fullWidth
                onChange={(e) => setData({ ...data, [messageInput.value]: e.target.value })}
                multiline
                maxRows={10}
                required
                disabled={loading}
              />
              {data.client === "Operational assistance" && (
                <>
                  <Typography variant="small">
                    <Trans>Select a type of operational assistance:</Trans>
                  </Typography>
                  <Grid container xs={12} alignItems="center" spacing={1} mt="0px !important">
                    {optionsOperationalAssistance().map(({ value, label }) => (
                      <Grid item xs={12} sm={6}>
                        <Checkbox
                          checked={value === data.category}
                          onChange={() => setData({ ...data, category: value })}
                        >
                          {label}
                        </Checkbox>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
              {data.client === "Client Complaints" && (
                <>
                  <Typography variant="small">
                    <Trans>
                      Office to which you wish to direct this message:
                    </Trans>
                  </Typography>
                  <Autocomplete
                    disablePortal
                    options={optionsOffices()}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Offices" />
                    )}
                    onChange={(_, item) =>
                      setData({
                        ...data,
                        office: item.value,
                        complaint_emails: item.complaint_emails,
                      })}
                    disabled={loading}
                  />
                </>
              )}
              <Grid container spacing={1} sx={{ fontStyle: "italic" }}>
                <Grid item display="flex" alignItems="center">
                  <Checkbox
                    checked={data.checkbox_terms}
                    onChange={() => setData({ ...data, checkbox_terms: !data?.checkbox_terms })}
                  >
                    <Trans>
                      I have read and accepted Allfunds general terms of use within the
                      {" "}
                      <LocalizedLink to="/legal-notice">Legal Notice</LocalizedLink>
                      {" "}
                      section
                    </Trans>
                  </Checkbox>
                </Grid>
                <Grid item display="flex" alignItems="center">
                  <Checkbox
                    checked={data.checkbox_policy}
                    onChange={() => setData({ ...data, checkbox_policy: !data?.checkbox_policy })}
                  >
                    <Trans>
                      I have read and accepted{" "}
                      <LocalizedLink to="/privacy-policy">
                        Allfunds Privacy Policy
                      </LocalizedLink>
                    </Trans>
                  </Checkbox>
                </Grid>
              </Grid>
              <Reaptcha
                hl={locale}
                sitekey={process.env.GATSBY_RECAPTCHA_KEY}
                onVerify={(token) => setData({ ...data, "g-recaptcha-response": token })}
              />
              <Box>
                <LoadingButton
                  variant="contained"
                  color="secondary"
                  disabled={
                    !data.checkbox_terms ||
                    !data.checkbox_policy ||
                    !data["g-recaptcha-response"] ||
                    !Mail.validate(inputs(), data)
                  }
                  onClick={() => sendContact()}
                  loading={loading}
                >
                  <Typography>
                    <Trans>Send</Trans>
                  </Typography>
                </LoadingButton>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} justifyContent="end" display="flex">
            <Box
              sx={{
                height: { xs: "480px", sm: "680px" },
                width: "580px",
                display: "block",
                backgroundSize: "cover",
                backgroundImage: `url(${contact_image})`,
                position: "relative",
                backgroundPosition: "center",
                borderRadius: "5px",
                boxShadow: "25px 25px 0 0 #D50032",
              }}
            />
          </Grid>
        </Grid>
        <InterestedItems items={interestedItems.splice(0, 3)} />
      </Container>
    </NewLayout>
  );
}

export default CustomerCarePage;
